<template>
<g-card page-title="System Logs" page-icon="mdi-database-settings">
  <v-row>
    <v-col :key="key" md="4" sm="6" xs="12" v-for="(item,key ) in logItems">
      <dashboard-widget :widget-items="item"/>
    </v-col>
  </v-row>
</g-card>
</template>

<script>
import PageTitle from "../../components/PageTitle";
import IsAuthenticated from "../../middleware/IsAuthenticated";
import DashboardWidget from "../../components/widget/DashboardWidget";
import SystemLogs from "../../services/SystemLogs";
import GCard from "../../components/GCard";
export default {
  name: "Reports",
  mixins: [IsAuthenticated],
  metaInfo: {
    title: 'rabbiitfirm.com',
    titleTemplate: 'Admin Dashboard - Reports | %s',
    meta: [
      { name: 'robots', content: 'noindex' },
      { name: 'googlebot', content: 'noindex' }
    ]
  },
  components: {
    GCard,
    DashboardWidget,
    PageTitle
  },
  data() {
    return {
      logItems: [
        {
          title: 'Queued Jobs',
          type:'queued',
          value: 0,
          icon: 'mdi-comment-processing',
          color: 'rgba(247, 105, 33, 0.9)',
          action: '/queued-job-logs'
        },
        {
          title: 'Failed Jobs',
          type:'failedJobs',
          value: 0,
          icon: 'mdi-comment-alert',
          color: 'rgba(107, 117, 21, 0.9)',
          action: '/failed-queued-logs'
        },
        {
          title: 'Schedulers',
          type: 'taskSchedulers',
          value: 0,
          icon: 'mdi-folder-network',
          color: 'rgba(5, 22, 86, 0.9)',
          action: '/task-scheduler'
        },
        {
          title: 'Mail Box',
          type: 'mailBox',
          value: 0,
          icon: 'business',
          color: 'rgba(55,89,238,0.9)',
          action: '/mail-box'
        },
        {
          title: 'Site Logs',
          type: 'siteLogs',
          value: 0,
          icon: 'mdi-lock',
          color: 'rgba(247, 105, 33, 0.9)',
          action: '/site-logs'
        },
        {
          title: 'Newsletter Logs',
          type: 'newsletterLogs',
          value: 0,
          icon: 'mdi-contacts',
          color: 'rgba(107, 117, 21, 0.9)',
          action: '/newsletter-logs'
        },
        {
          title: 'Delivery Email Logs',
          type: 'deliveryEmailLogs',
          value: 0,
          icon: 'mdi-truck-delivery',
          color: 'rgba(5, 22, 86, 0.9)',
          action: '/delivery-email-logs'
        },
        {
          title: 'Duplicated Links Logs',
          type: 'duplicatedLinksLogs',
          value: 0,
          icon: 'mdi-link-variant',
          color: 'error',
          action: '/duplicated-links-logs'
        },
      ]
    }
  },
  mounted() {
    this.systemLogsHandler();
  },
  methods: {
    systemLogsHandler() {
      SystemLogs.all({})
      .then(({data: { data: { queued,
        failedJobs,
        taskSchedulers,
        mailBox,
        siteLogs,
        newsletterLogs,
        deliveryEmailLogs,
        duplicatedLinksLogs, }}})=> {

       this.logItems = this.logItems.map((item) => {
          if(item.type === 'queued') {
            return {
              ...item,
              value: queued
            }
          } else if(item.type === 'failedJobs') {
            return {
              ...item,
              value: failedJobs
            }
          } else if(item.type === 'taskSchedulers') {
            return {
              ...item,
              value: taskSchedulers
            }
          } else if(item.type === 'mailBox') {
            return {
              ...item,
              value: mailBox
            }
          } else if(item.type === 'siteLogs') {
            return {
              ...item,
              value: siteLogs
            }
          } else if(item.type === 'newsletterLogs') {
            return {
              ...item,
              value: newsletterLogs
            }
          } else if(item.type === 'deliveryEmailLogs') {
            return {
              ...item,
              value: deliveryEmailLogs
            }
          } else if(item.type === 'duplicatedLinksLogs') {
            return {
              ...item,
              value: duplicatedLinksLogs
            }
          } else {
            return item;
          }
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">
</style>